export class coveragesPfResponse{
    constructor(
        public coveragesPf: coveragesPfdata
    ) { }
}
export class coveragesPfdata
{
    policyAggregate: number;
    aggregatePerInsuredEvent: number;
    selfInsuredRetention: number;
    insuredLosses: insuredLosses;
    insuredEvents: insuredEvents;    
    riskId: string;
    submissionId: string;
    quoteNumber: string;
    quoteId: string;
    insuredName: string;
    broker: string;
    lob: string;
    lobId: string;
    transactionType: string;
    status: string;
    stateCode: string;
    stateName: string;
    countryCode: string;
    termLength: string;
    effectiveDate: string;
    expirationDate: string;
    quoteShare: boolean;
    hccSharePercentage: number;
    filingResponsibility: string;
    taxState: string;
    taxesAndFees: taxAndFeeData[];
    basePremium: number;
    triaPremium: number;
    taxesAndFeesPremium: number;
    totalPremium: number;
    termLengths: string[];
    filingResponsibilities: data[];
    surplusLineTaxes: data[];
    primaryCarrier: dataCompany[];
    indemnityPeriods: data[];
    excessPolicyTypes: string;
    excessCoverageTypes: string;
    triaIncluded: boolean;
    triaRatePercentage: number;
    treatyCoverageCodes: TreatyCoverageCodes[];
    policyType: string;
    lobTypeId: number;
    treaties:treaties[]
}
export class treaties
{
  coverageId?: string;
  triaId?: string;
  layer?: number;
  coverageCode: string;
  contractCode: string;
  limit: number;
  reinsuranceTreaty: string;
  tmhccRetention: number;
  cedingCommission: number;
  sedgwick: number;  
  premium?: number;
  tria?: number;
}
export class TreatyCoverageCodes{    
    covTypeId: number;
    covTypeCode: string;    
}
export class data{    
    id: number;
    description: string;    
}
export class dataCompany{    
    id: number;
    companyName: string;    
}
export class taxAndFeeData{    
    description: string;
    amount: number;   
}
export class insuredLosses{
    recallContaminationExpenses: KeyValue;
    preRecallContaminationExpenses: KeyValue;
    unsubstantiatedRetailerCosts: KeyValue;
    valueOfContaminatedProducts: KeyValue;
    lostGrossProfit: KeyValue;
    rehabilitationExpenses: KeyValue;
    increasedCostOfWorking: KeyValue;
    extortionCosts: KeyValue;    
    crisisConsultantExpenses: KeyValue;
    triaRatePercentage: number;
}
export class KeyValue{
    id: string;
    value: number;
}
export class insuredEvents{
    accidentalProductContamination: KeyValue;
    maliciousProductTampering: KeyValue;
    intentionallyImpairedIngredients: KeyValue;
    adversePublicity: KeyValue;
    governmentRecall: KeyValue;
}